import React, { useCallback, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import moment, { Moment } from 'moment';
import { styled } from 'styled-components';
import { debounce } from 'lodash';
import { generateUniqId } from 'helpers/utils';
import { AccountsHelpers } from 'helpers/accounts';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import {
  FetchTransactionBalancesPayload,
  transfersAPI,
} from 'api/accounting/transfersAPI';

// components
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import FormErrorTooltip from 'components/Forms/FormErrorTooltip';
import WireTransfersTable from './WireTransfersTable';
import { Col, Row, AddButton } from '@ui';

export type TransactionModel = {
  customId: string;
  from: string | null;
  fromFieldLabel: string | null;
  balance: {
    actual: number;
    available: number | null;
  } | null;
  purpose: string;
  amount: number | null;
  currencyId: number | null;
  currency: string | null;
  valueDate: Moment | null;
  fromReference: string;
  to: string | null;
  toFieldLabel: string | null;
  toReference: string;
};

export type FormValuesModel = {
  transactions: TransactionModel[];
};

const MAX_TRANSACTIONS_LIMIT = 200;
export const TRANSACTIONS_PAGE_SIZE_LIMIT = 10;

const WireTransferGridForm = () => {
  const { t } = useTranslation('transfers');
  const { values, isSubmitting, setSubmitting, setValues } =
    useFormikContext<FormValuesModel>();
  const [page, setPage] = useState(1);

  const updateAvailableBalances = async (
    newTransactions: TransactionModel[],
  ) => {
    const formattedTransactions =
      newTransactions.reduce<FetchTransactionBalancesPayload>((acc, curr) => {
        if (curr.from) {
          acc.push({
            sequenceNumber: curr.customId,
            fromAccountNumber: curr.from,
            currencyId: curr.currencyId as number,
            amount: curr.amount
              ? AccountsHelpers.convertAmountFromIntToBigInt(curr.amount)
              : 0,
          });
        }

        return acc;
      }, []);

    setSubmitting(true);

    const response = await transfersAPI.fetchBalancesForWireTransfers(
      formattedTransactions,
    );

    const updatedTransactions = newTransactions.map((transaction) => {
      const updatedTransaction = response.find(
        (responseTransaction) =>
          responseTransaction.sequenceNumber === transaction.customId,
      );

      if (updatedTransaction) {
        return {
          ...transaction,
          balance: transaction.balance
            ? {
                ...transaction.balance,
                available: AccountsHelpers.convertAmountFromBigIntToInt(
                  updatedTransaction.availableBalance,
                ),
              }
            : null,
        };
      }

      return transaction;
    });

    setValues({ transactions: updatedTransactions });
    setSubmitting(false);
  };

  const debouncedUpdateAvailableBalances = useCallback(
    debounce(updateAvailableBalances, 300),
    [],
  );

  const handlePageUpdateAfterAdd = (newTransactionsCount: number) => {
    const newPage = Math.ceil(
      newTransactionsCount / TRANSACTIONS_PAGE_SIZE_LIMIT,
    );
    setPage(newPage);
  };

  const handlePageUpdateAfterDelete = (newTransactionsCount: number) => {
    const newPage = Math.ceil(
      newTransactionsCount / TRANSACTIONS_PAGE_SIZE_LIMIT,
    );
    if (page > newPage) {
      setPage(newPage);
    }
  };

  const deleteRow = (rowIndex: number) => {
    const arrayCopy = [...values.transactions];
    arrayCopy.splice(rowIndex, 1);
    setValues({ transactions: arrayCopy });
    handlePageUpdateAfterDelete(arrayCopy.length);

    if (arrayCopy.length > 0) {
      debouncedUpdateAvailableBalances(arrayCopy);
    }
  };

  const renderAddButton = useCallback(
    (fieldProps: FieldArrayRenderProps) => {
      const newTransaction: TransactionModel = {
        customId: generateUniqId(),
        from: null,
        fromFieldLabel: '',
        balance: null,
        purpose: '',
        amount: null,
        currencyId: null,
        currency: null,
        valueDate: moment(),
        fromReference: '',
        to: null,
        toFieldLabel: null,
        toReference: '',
      };

      if (values.transactions.length === MAX_TRANSACTIONS_LIMIT) {
        return (
          <Row>
            <Col>
              <InfoTooltip
                tooltipContent={t(
                  'international_transfer.submit_multiple_grid.max_add_transaction_limit',
                  { limit: MAX_TRANSACTIONS_LIMIT },
                )}
              >
                <div>
                  <StyledAddButton disabled>
                    {t(
                      'international_transfer.submit_multiple_grid.add_transaction_button',
                    )}
                  </StyledAddButton>
                </div>
              </InfoTooltip>
            </Col>
          </Row>
        );
      } else {
        return (
          <StyledAddButton
            disabled={isSubmitting}
            onClick={() => {
              fieldProps.push(newTransaction);
              handlePageUpdateAfterAdd(
                fieldProps.form.values.transactions.length + 1,
              );
            }}
          >
            {t(
              'international_transfer.submit_multiple_grid.add_transaction_button',
            )}
          </StyledAddButton>
        );
      }
    },
    [isSubmitting],
  );

  return (
    <FieldArray
      name="transactions"
      render={(props) => (
        <>
          <WireTransfersTable
            page={page}
            pageSize={TRANSACTIONS_PAGE_SIZE_LIMIT}
            onDelete={deleteRow}
            onPaginationPage={setPage}
          />
          <FormErrorTooltip<FormValuesModel> errorKey="transactions" />
          {renderAddButton(props)}
        </>
      )}
    />
  );
};

const StyledAddButton = styled(AddButton)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

export default WireTransferGridForm;
