import React, { useMemo } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { StateModel } from 'redux/reducers';
import { DateHelpers } from 'helpers/date';
import { ContactModel } from 'typings/application/contact';
import { FormikHelpers } from 'formik';
import { ContactHelpers } from 'helpers/crm/contact';
import { SocialMediaTypes } from 'enums/onboarding/crm';
import { setChangeRequest } from 'redux/actions/personalDetails';
import { OnboardingHelpers } from 'helpers/crm/onboarding';
import { useDispatch, useSelector } from 'react-redux';
import { personalDetailsAPIAdapter } from 'apiAdapters/profile/personalDetailsAPIAdapter';
import {
  ChangeRequestFromQuery,
  ChangeRequestStatuses,
  personalDetailsAPI,
} from 'api/profile/personalDetailsAPI';

// components
import ApplicantInformationForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Onboarding/ApplicantInformationForm';
import { Message } from '@ui';

const ApplicantInformation = () => {
  const { t } = useTranslation(['common', 'profile']);
  const dispatch = useDispatch();
  const contact = useSelector<StateModel, ContactModel | null>(
    (state) => state.personalDetails.contact,
  );
  const changeRequest = useSelector<StateModel, ChangeRequestFromQuery | null>(
    (state) => state.personalDetails.changeRequest,
  );
  const primaryEmail = useSelector<
    StateModel,
    { email: string; isVerified: boolean } | null
  >((state) =>
    state.auth.profileData?.email
      ? {
          email: state.auth.profileData?.email,
          isVerified: state.auth.profileData.emailConfirmed,
        }
      : null,
  );

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!contact) {
      return null;
    }

    const contactData =
      changeRequest?.changes?.contactKYCChange?.general || contact;

    // Here we need to take phone numbers from contact because change request doesn't store phone number verification status
    // TODO: rework this process to take verification status from change request
    const primaryPhoneNumber = contact.phoneNumbers.find(
      (phone) => phone.isPrimary,
    );

    let emails = [];
    if (contactData?.emails.length) {
      emails = contactData.emails.map((e) => ({
        type: e.type,
        value: e.address,
        primary: {
          status: e.isPrimary,
          canEdit: !e.isPrimary,
        },
        isVerified:
          e.address === primaryEmail?.email ? primaryEmail.isVerified : false,
        disabledEmailAddressFieldTooltipText: e.isPrimary
          ? t('personal_details.kyc_form.primary_email_address_tooltip')
          : undefined,
      }));
    } else {
      emails = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
          isVerified: false,
        },
      ];
    }

    let phoneNumbers = [];
    if (contactData.phoneNumbers.length) {
      phoneNumbers = contactData.phoneNumbers.map((e) => ({
        type: e.type,
        value: e.number,
        primary: {
          status: e.isPrimary,
          canEdit: !e.isPrimary,
        },
        isVerified:
          !!e?.verification?.isVerified ||
          (e.number == primaryPhoneNumber?.number &&
            primaryPhoneNumber.verification?.isVerified),
      }));
    } else {
      phoneNumbers = [
        {
          type: null,
          value: '',
          primary: {
            status: true,
            canEdit: true,
          },
          isVerified: false,
        },
      ];
    }

    return {
      isSave: false,
      submitActionType: null,
      firstName: contactData.firstName || '',
      middleName: contactData.middleName || '',
      lastName: contactData.lastName || '',
      dateOfBirth: contactData.dateOfBirth
        ? DateHelpers.formatDateToUTC(contactData.dateOfBirth)
        : null,
      countryOfBirth: contactData.countryOfBirth || '',
      gender: contactData.gender || null,
      emails,
      phoneNumbers,
      socialMedia: {
        twitterLink: contactData.socialMedia
          ? OnboardingHelpers.findSocialMediaByType(
              SocialMediaTypes.Twitter,
              contactData?.socialMedia,
            )
          : '',
        facebookLink: contactData.socialMedia
          ? OnboardingHelpers.findSocialMediaByType(
              SocialMediaTypes.Facebook,
              contactData?.socialMedia,
            )
          : '',
        linkedInLink: contactData.socialMedia
          ? OnboardingHelpers.findSocialMediaByType(
              SocialMediaTypes.LinkedIn,
              contactData?.socialMedia,
            )
          : '',
      },
      canVerifyPrimaryEmail: false,
      isContactBaseInfoFormDisabled: false,
      canVerifyPrimaryPhoneNumber: false,
    };
  }, [contact, primaryEmail, changeRequest]);

  const handleSubmit = async (
    values: FormValuesModel,
    helpers: FormikHelpers<FormValuesModel>,
  ) => {
    if (changeRequest) {
      const errors = await ContactHelpers.validatePhoneNumbers(
        values.phoneNumbers.map((e) => e.value),
      );
      if (errors) {
        values.phoneNumbers.forEach((phone, index) => {
          if (errors[phone.value]) {
            helpers.setFieldError(
              `phoneNumbers.${index}.value`,
              errors[phone.value].errorMessage,
            );
          }
        });
      } else {
        const response = await personalDetailsAPI.submitKYCGeneralTab(
          changeRequest._id,
          personalDetailsAPIAdapter.formatKYCGeneralFormValuesAPIPayload(
            values,
          ),
        );

        Message.success(t('success_save'));
        dispatch(setChangeRequest(response));
      }
    }
  };

  return initialFormValues ? (
    <ApplicantInformationForm
      showOnlySaveButton
      disabled={
        !changeRequest ||
        changeRequest.status !== ChangeRequestStatuses.InProgress
      }
      initialFormValues={initialFormValues}
      onSubmit={handleSubmit}
    />
  ) : null;
};

export default ApplicantInformation;
