import React, { useMemo, useState } from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { useField } from 'formik';
import { colorsTheme } from 'resources/theme/styled/colors';
import { TransactionModel } from 'components/Forms/TemplateForms/Transfers/WireTransferGridForm';
import { DateHelpers } from 'helpers/date';
import { AccountsHelpers } from 'helpers/accounts';

// component
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import { Text, Table, TableColumnModel } from '@ui';

const WireTransfersSummaryTable = () => {
  const { t } = useTranslation('transfers');
  const [field] = useField<TransactionModel[]>('transactions');
  const [page, setPage] = useState(1);
  const columns = useMemo<TableColumnModel[]>(() => {
    const getIndexOfItemInArray = (page: number, indexInPage: number) => {
      return (page - 1) * 10 + indexInPage;
    };

    return [
      {
        width: 50,
        key: 'index',
        render: (record: TransactionModel, options: any, index: number) => {
          const itemIndex = getIndexOfItemInArray(page, index);
          return (
            <IndexValueWrapper>
              <Text weight="semi-bold">{itemIndex + 1}</Text>
            </IndexValueWrapper>
          );
        },
      },

      {
        width: 285,
        key: 'from',
        title: t('international_transfer.submit_multiple_grid.from'),
        render: (record: TransactionModel) => {
          return (
            <EllipsisTooltip
              maxTextContainerWidth="270px"
              title={record.fromFieldLabel}
            >
              {record.fromFieldLabel}
            </EllipsisTooltip>
          );
        },
      },

      {
        width: 200,
        key: 'available_balance',
        title: (
          <StyledDivAlignCenter>
            {t('international_transfer.submit_multiple_grid.balance')}
            <InfoTooltip
              tooltipContent={t(
                'international_transfer.submit_multiple_grid.available_balance_info',
              )}
            />
          </StyledDivAlignCenter>
        ),
        render: (record: TransactionModel) => {
          return (
            <>
              {record.balance && record.balance?.available !== null ? (
                <Text
                  variant="body1"
                  color={
                    record.balance.available < record.balance.actual
                      ? colorsTheme.colorWarning
                      : undefined
                  }
                >
                  {`${AccountsHelpers.formatAmountToLocaleString(record.balance.available)}`}
                </Text>
              ) : (
                ''
              )}
            </>
          );
        },
      },

      {
        width: 285,
        key: 'purpose',
        title: t('international_transfer.submit_multiple_grid.purpose'),
        render: (record: TransactionModel) => {
          return (
            <EllipsisTooltip
              maxTextContainerWidth="270px"
              title={record.purpose}
            >
              {record.purpose}
            </EllipsisTooltip>
          );
        },
      },

      {
        width: 200,
        key: 'amount',
        title: t('international_transfer.submit_multiple_grid.amount'),
        render: (record: TransactionModel) => {
          return record.amount;
        },
      },

      {
        width: 200,
        key: 'currency',
        title: t('international_transfer.submit_multiple_grid.currency'),
        render: (record: TransactionModel) => {
          return record.currency;
        },
      },

      {
        width: 200,
        key: 'valueDate',
        title: t('international_transfer.submit_multiple_grid.value_date'),
        render: (record: TransactionModel) => {
          return record.valueDate
            ? DateHelpers.formatDateToString(record.valueDate)
            : '';
        },
      },

      {
        width: 285,
        key: 'fromReference',
        title: t('international_transfer.submit_multiple_grid.from_reference'),
        render: (record: TransactionModel) => {
          return (
            <EllipsisTooltip
              maxTextContainerWidth="270px"
              title={record.fromReference}
            >
              {record.fromReference}
            </EllipsisTooltip>
          );
        },
      },

      {
        width: 285,
        key: 'to',
        title: t('international_transfer.submit_multiple_grid.to'),
        render: (record: TransactionModel) => {
          return (
            <EllipsisTooltip
              maxTextContainerWidth="270px"
              title={record.toFieldLabel}
            >
              {record.toFieldLabel}
            </EllipsisTooltip>
          );
        },
      },

      {
        width: 285,
        key: 'toReference',
        title: t('international_transfer.submit_multiple_grid.to_reference'),
        render: (record: TransactionModel) => {
          return (
            <EllipsisTooltip
              maxTextContainerWidth="270px"
              title={record.toReference}
            >
              {record.toReference}
            </EllipsisTooltip>
          );
        },
      },
    ];
  }, [page]);

  return (
    <Table
      bordered
      columns={columns}
      data={field.value}
      current={page}
      onPaginationChange={setPage}
    />
  );
};

const IndexValueWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDivAlignCenter = styled(DivAlignCenter)`
  gap: ${({ theme }) => theme.marginXXs};
`;

export default WireTransfersSummaryTable;
